import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currencySymbol: "₨",
  currencyName: "PKR",
  currencyRate: 1,
  exchangeRates: { 
    USD: null,
    EUR: null,
  },
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  
  reducers: {
    setCurrency(state, action) {
      const currencyName = action.payload;

      const usdRate = state.exchangeRates.USD ?? 280; 
      const eurRate = state.exchangeRates.EUR ?? 300; 

      console.log(state.exchangeRates.USD, state.exchangeRates.EUR);
      

      switch (currencyName) {
        case "PKR":
          state.currencySymbol = "₨";
          state.currencyRate = 1; 
          state.currencyName = currencyName;
          break;
        case "USD":
          state.currencySymbol = "$";
          state.currencyRate = usdRate;
          state.currencyName = currencyName;
          break;
        case "EUR":
          state.currencySymbol = "€";
          state.currencyRate = eurRate; 
          state.currencyName = currencyName;
          break;
        default:
          break;
      }
    },
    
    setExchangeRates(state, action) {
      const { USD, EUR } = action.payload;
      state.exchangeRates = {
        USD: USD !== undefined ? USD : state.exchangeRates.USD, 
        EUR: EUR !== undefined ? EUR : state.exchangeRates.EUR,
      };
    },
  },
});

export const { setCurrency, setExchangeRates } = currencySlice.actions;
export default currencySlice.reducer;



// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   currencySymbol: "₨",
//   currencyName: "PKR",
//   currencyRate: 1,
//   exchangeRates: { 
//     USD: null,
//     EUR: null,
//   },
// };

// const currencySlice = createSlice({
//   name: "currency",
//   initialState,
  
//   reducers: {
//     setCurrency(state, action) {
//       const currencyName = action.payload;
//       const pkrRate = state.exchangeRates.USD; 
//       const usdRate = state.exchangeRates.USD ?? 280; 
//       const eurRate = state.exchangeRates.EUR ?? 300; 

//       console.log(pkrRate, usdRate, eurRate);

//       switch (currencyName) {
//         case "PKR":
//           state.currencySymbol = "₨";
//           state.currencyRate = pkrRate; 
//           state.currencyName = currencyName;
//           break;
//         case "USD":
//           state.currencySymbol = "$";
//           state.currencyRate = usdRate;
//           state.currencyName = currencyName;
//           break;
//         case "EUR":
//           state.currencySymbol = "€";
//           state.currencyRate = eurRate; 
//           state.currencyName = currencyName;
//           break;
//         default:
//           break;
//       }
//     },
    
//     setExchangeRates(state, action) {
//       const { USD, EUR, PKR } = action.payload;
//       state.exchangeRates = {
//         PKR: PKR !== undefined ? PKR : state.exchangeRates.PKR, 
//         USD: USD !== undefined ? USD : state.exchangeRates.USD, 
//         EUR: EUR !== undefined ? EUR : state.exchangeRates.EUR,
//       };
//     },
//   },
// });

// export const { setCurrency, setExchangeRates } = currencySlice.actions;
// export default currencySlice.reducer;
