import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));

const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() => import("./pages/shop-product/ProductTabLeft"));
const ProductTabRight = lazy(() => import("./pages/shop-product/ProductTabRight"));
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() => import("./pages/shop-product/ProductFixedImage"));

const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));

const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Measurements = lazy(() => import("./pages/measurements/measurements"));
const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }>
          <Routes>
            <Route path="/" element={<HomeFashion />} />

            <Route path="/home-fashion" element={<HomeFashion />} />
            <Route path="/measurements" element={<Measurements />} />

            <Route path="/shop-grid-no-sidebar" element={<ShopGridNoSidebar />} />

            <Route path="/product/:id" element={<Product />} />
            <Route path="/product-tab-left/:id" element={<ProductTabLeft />} />
            <Route path="/product-tab-right/:id" element={<ProductTabRight />} />
            <Route path="/product-sticky/:id" element={<ProductSticky />} />
            <Route path="/product-slider/:id" element={<ProductSlider />} />
            <Route path="/product-fixed-image/:id" element={<ProductFixedImage />} />

            <Route path="/blog-no-sidebar" element={<BlogNoSidebar />} />

            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/login-register" element={<LoginRegister />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/compare" element={<Compare />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;
